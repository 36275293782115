<template>

  <div class="home">

    <div
      v-if="!pageLoad"
      class="page-loader"
    >
      <div
        class="spinner-border text-primary"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex py-2 justify-content-between">

      <h1 class="h4 mb-0">
        «Корректировка потребности» № {{ currentAdjustment.number }} от {{ currentAdjustment.date | parseDate }}
      </h1>

      <div class="d-flex align-items-center">
        <BButton
          class="ml-3"
          variant="warning"
        >Экпорт корректировки
        </BButton>
        <BButton
          v-if="canApprove"
          class="ml-3"
          variant="danger"
          v-b-modal.adjustment-reject
        >Отказать
        </BButton>
        <BButton
          v-if="canApprove"
          class="ml-3"
          variant="primary"
          v-b-modal.adjustment-approve
        >Согласовать
        </BButton>
      </div>

    </div>

    <div class="d-flex align-items-center justify-content-between">

      <TableFilter
        :columns="adjustmentOrdersFields"
        uniqueKey="table-1"
        @update-selected-columns="columns = $event"
        @search="filter = $event"
        @clear="clearFilterData"
        @open-filter-modal="$root.$emit('bv::toggle::collapse', 'filter')"
      />

      <div
        v-if="refusePositionList.length > 0 && canApprove"
        class="d-flex align-items-center"
      >
        <span>{{$options.filters.declensionNumbers(refusePositionList.length, ['Выбрана', 'Выбрано', 'Выбраны'])}} {{refusePositionList.length}} {{$options.filters.declensionNumbers(refusePositionList.length, ['позиция', 'позиции', 'позиций'])}}</span>
        <BButton
          class="ml-3"
          variant="warning"
          v-b-modal.mtr-reject
        >Отказать и дать комментарий по выбранным позициям
        </BButton>
      </div>

    </div>

    <DefaultTable
      :data="currentAdjustment.positions"
      :fields="adjustmentOrdersFieldsFiltered"
      :filter="filter"
      :selectedColumns="refusePositionList"
      :withCommentModal="true"
      :withSpecifiedTotal="getAdjustmentTotal"
      :loading="loading"
      :noLocalSorting="true"
      @selected-rows="refusePositionList = $event"
      @refresh-table="getData()"
      @sort-change="sorting"
      @pagination-change="paginationChange"
      @per-page-change="perPageChange"
    />

    <b-modal
      id="adjustment-reject"
      centered
      title="Добавить комментарий по отказу"
    >
      <b-form-textarea
        v-model.trim="rejectComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
        <b-button
          variant="primary"
          @click="rejectAdjustment"
        >
          Добавить
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="adjustment-approve"
      centered
      title="Добавить комментарий по согласованию"
    >
      <b-form-textarea
        v-model.trim="approveComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
        <b-button
          variant="primary"
          @click="approveAdjustment"
        >
          Добавить
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="mtr-reject"
      centered
      title="Добавить комментарий"
    >
      <b-form-textarea
        v-model.trim="mtrRejectComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button
          variant="outline-primary"
          @click="hide()"
        >
          Отмена
        </b-button>
        <b-button
          variant="primary"
          @click="mtrReject"
        >
          Добавить
        </b-button>
      </template>
    </b-modal>

    <FilterSidebar
      id="filter"
      v-model="filterData"
      @apply="updatePositionsWithFilters(true)"
    />

  </div>

</template>

<script>
import { mapState } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import FilterSidebar from "@/components/FilterSidebar";

function initialFilterData() {
  return {
    status: "",
    mnemocode: "",
    nomenclature_name: "",
    price_without_vat: "",
    delivery_address: "",
    delivery_time: ["", ""],
  };
}

export default {
  name: "Adjustment",
  components: { DefaultTable, TableFilter, FilterSidebar },
  computed: {
    id() {
      return this.$route.params.id;
    },
    orderId() {
      return this.$route.params.orderId;
    },
    ...mapState({
      providerOrder: (state) => state.providerOrders.currentProviderOrder,
      currentAdjustment: (state) => state.adjustment.currentAdjustment,
    }),
    adjustmentOrdersFieldsFiltered() {
      let res = [];
      this.adjustmentOrdersFields.forEach((item) => {
        if (item.key === "select") {
          if (this.currentAdjustment.provider_status !== "На рассмотрении") {
            return false;
          }
        }
        if (this.columns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.adjustmentOrdersFields;
    },
    canApprove() {
      return this.currentAdjustment.provider_status === "На рассмотрении";
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ СК", link: "/" },
        {
          title: "Перечень заказов поставщику",
          link: "/provider/purchase-orders",
        },
        {
          title: `Заказ на поставку № ${
            this.currentAdjustment.provider_order.number
          } от ${this.$options.filters.parseDate(
            this.currentAdjustment.provider_order.order_date
          )}`,
          link: `/provider/purchase-orders/${this.orderId}`,
        },
        {
          title: `Корректировка потребности № ${
            this.currentAdjustment.number
          } от ${this.$options.filters.parseDate(this.currentAdjustment.date)}`,
        },
      ];
    },
    getAdjustmentTotal() {
      return `Всего сумма по документу: без НДС - ${
        this.currentAdjustment.common_amount_without_vat || "Не указано"
      } руб., с НДС - ${
        this.currentAdjustment.common_amount_with_vat || "Не указано"
      } руб.`;
    },
  },
  data: () => ({
    adjustmentOrdersFields: [
      {
        key: "select",
        sortable: false,
        label: "",
        full: "",
        openDefault: true,
      },
      {
        key: "status",
        label: "С",
        full: "Статус позиции",
        openDefault: true,
        sortable: true,
      },
      {
        key: "mnemocode",
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
        sortable: true,
      },
      {
        key: "nomenclature_name",
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
        sortable: true,
      },
      {
        key: "count",
        label: "Кол-во",
        full: "Количество",
        openDefault: true,
        sortable: true,
      },
      {
        key: "unit",
        sortable: true,
        label: "Ед. изм.",
        full: "Единица измерения",
        openDefault: true,
      },
      {
        key: "price_without_vat",
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "amount_without_vat",
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "vat_rate",
        label: "Ставка НДС",
        full: "Ставка НДС",
        openDefault: true,
        sortable: true,
      },
      {
        key: "amount_with_vat",
        label: "Сумма ₽ (с НДС)",
        full: "Сумма ₽ (с НДС)",
        openDefault: true,
        sortable: true,
      },
      {
        key: "delivery_time",
        label: "Срок поставки",
        full: "Срок поставки",
        openDefault: true,
        sortable: true,
      },
      {
        key: "delivery_address",
        label: "Адрес поставки",
        full: "Адрес поставки",
        openDefault: true,
        sortable: true,
      },
      {
        key: "organization_comment",
        label: "Комм",
        full: "Комментарии",
        openDefault: true,
        sortable: true,
      },
      {
        key: "delta",
        label: "Дельта (Расхождение)",
        full: "Дельта (Расхождение)",
        openDefault: true,
        sortable: true,
      },
    ],
    columns: [],
    filterData: initialFilterData(),
    filter: "",
    loading: false,
    pageLoad: false,
    refusePositionList: [],
    approveComment: "",
    rejectComment: "",
    mtrRejectComment: "",
    sortingData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),
  methods: {
    async getData() {
      this.pageLoad = false;
      this.$bvModal.hide("mtr-reject");
      this.$bvModal.hide("adjustment-reject");
      this.$bvModal.hide("adjustment-approve");
      await Promise.all([
        this.$store.dispatch("getAdjustmentData", {
          orderId: this.orderId,
          id: this.id,
        }),
        this.$store.dispatch("getAdjustmentPositions", {
          orderId: this.orderId,
          id: this.id,
        }),
      ]);
      this.pageLoad = true;
    },
    async rejectAdjustment() {
      await this.$store
        .dispatch("rejectAdjustment", {
          orderId: this.orderId,
          id: this.id,
          comment: this.rejectComment,
        })
        .then(() => {
          this.sendSuccess("Корректировка успешно отклонена");
          this.getData();
        })
        .catch((err) => {
          this.sendError(err);
        });
      this.$bvModal.hide("reject");
    },
    async approveAdjustment() {
      await this.$store
        .dispatch("approveAdjustment", {
          orderId: this.orderId,
          id: this.id,
          comment: this.approveComment,
        })
        .then(() => {
          this.sendSuccess("Корректировка успешно согласована");
          this.getData();
        })
        .catch((err) => {
          this.sendError(err);
        });
    },
    mtrReject() {
      this.$store
        .dispatch("rejectAdjustmentMtr", {
          orderId: this.orderId,
          id: this.id,
          rejected_position_ids: this.refusePositionList,
          comment: this.mtrRejectComment,
        })
        .then(async () => {
          this.sendSuccess("Отказано в выбранных позициях");
          this.$bvModal.hide("mtr-reject");
          this.mtrRejectComment = "";
          this.getData();
        })
        .catch(() => {
          this.sendError();
        });
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },
    async updatePositionsWithFilters(clearSort) {
      this.loading = true;
      if (clearSort) {
        this.sortingData.sort_field = "";
        this.sortingData.sort_order = "";
        this.sortingData.page = "";
      }
      await this.$store.dispatch("getAdjustmentPositions", {
        id: this.id,
        orderId: this.orderId,
        filters: {
          ...this.sortingData,
          ...this.filterData,
        },
      });
      this.loading = false;
    },
    clearFilterData() {
      this.filterData = initialFilterData();
      this.updatePositionsWithFilters(true);
    },
    sorting(event) {
      this.sortingData.sort_field = event.sortBy;
      this.sortingData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsWithFilters();
    },
    paginationChange(page) {
      this.sortingData.page = page;
      this.updatePositionsWithFilters();
    },
    perPageChange(perPage) {
      this.sortingData.page = "";
      this.sortingData.per_page = perPage;
      this.updatePositionsWithFilters();
    },
  },
  async mounted() {
    this.adjustmentOrdersFields.forEach((item) => {
      if (item.openDefault) {
        this.columns.push(item.label);
      }
    });
    await this.getData();
    this.$store.dispatch("checkNotification", {
      entity: "requirement_corrections",
      id: this.id,
    });
  },
};
</script>

<style scoped>
</style>
